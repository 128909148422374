<template>
  <div class="bbox">
    <Header :title="'香港开户特邀通道'" :back="true"/>
    <div class="title">香港账户优势</div>
    <div class="box-items">
      <div class="flex-box list-box">
        <div  v-for="item in ys" :key="item.icon">
          <img :src="require('@/assets/img/'+item.icon+'.png')"/>
          <div class="theme-color">{{item.title}}</div>
          <div class="sub-content">{{item.subtitle}}</div>
        </div>
      </div>
    </div>
    <div class="title">木星国际用户专享福利</div>
    <div class="box-items">
      <div class="flex-box list-box">
        <div  v-for="item in fl" :key="item.icon">
          <img :src="require('@/assets/img/'+item.icon+'.png')"/>
          <div class="theme-color">{{item.title}}</div>
          <div class="sub-content">{{item.subtitle}}</div>
        </div>
      </div>
    </div>
    <div class="title">八大银行开设香港账户全对比</div>
    <div class="box-items">
      <img class="img" src="@/assets/img/tab1.jpg"/>
      <img class="img" src="@/assets/img/tab2.jpg"/>
    </div>
    <div class="tips-1 theme-color">备注：外籍人士或者身份证是集体户口的申请人，需要提供地址证明</div>
     <div class="finacing-footer">
      <div class="add-btn theme-bgcolor" @click="lx">预约开户</div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return{
      ys:[{
        icon:'1',
        title:'无管制',
        subtitle:"跨境转账不受限"
      },{
        icon:'2',
        title:'更便捷',
        subtitle:"港险保费轻松续"
      },{
        icon:'3',
        title:'更自由',
        subtitle:"全球资产随心购"
      }],
       fl:[{
        icon:'1',
        title:'更低资金门槛',
        subtitle:"10万(原30万)资产"
      },{
        icon:'2',
        title:'更高办理效率',
        subtitle:"免排队、免赴港"
      },{
        icon:'3',
        title:'豁免费用',
        subtitle:"免手续费、免管理费"
      }]
    }
  },
  methods:{
    lx(){

    }
  }
  
}
</script>
<style lang="scss" scoped>
.list-box{
  justify-content: space-between;
  font-size: .9rem;
  color: #464646;
  text-align: center;
  .sub-content{
    font-size: .8rem;
  }
}
.box-items{
  .img{
    width: 100%;
  }
}
.tips-1{
  font-size: .8rem;
  padding:2% 8% 4%;
  padding-bottom: 80px;
}
.bbox{
  background: #F8F8F8;
}
.finacing-footer{
  background: #fff;
  position: fixed;
  bottom: 0px;
  width: 95%;
  padding: 2.5%;
  .add-btn{
    border-radius:8px;
    width: 100%;
    text-align: center;
    line-height: 2.5;
    color: #fff;
  }
}
</style>