var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bbox" },
    [
      _c("Header", { attrs: { title: "香港开户特邀通道", back: true } }),
      _c("div", { staticClass: "title" }, [_vm._v("香港账户优势")]),
      _c("div", { staticClass: "box-items" }, [
        _c(
          "div",
          { staticClass: "flex-box list-box" },
          _vm._l(_vm.ys, function (item) {
            return _c("div", { key: item.icon }, [
              _c("img", {
                attrs: { src: require("@/assets/img/" + item.icon + ".png") },
              }),
              _c("div", { staticClass: "theme-color" }, [
                _vm._v(_vm._s(item.title)),
              ]),
              _c("div", { staticClass: "sub-content" }, [
                _vm._v(_vm._s(item.subtitle)),
              ]),
            ])
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "title" }, [_vm._v("木星国际用户专享福利")]),
      _c("div", { staticClass: "box-items" }, [
        _c(
          "div",
          { staticClass: "flex-box list-box" },
          _vm._l(_vm.fl, function (item) {
            return _c("div", { key: item.icon }, [
              _c("img", {
                attrs: { src: require("@/assets/img/" + item.icon + ".png") },
              }),
              _c("div", { staticClass: "theme-color" }, [
                _vm._v(_vm._s(item.title)),
              ]),
              _c("div", { staticClass: "sub-content" }, [
                _vm._v(_vm._s(item.subtitle)),
              ]),
            ])
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "title" }, [
        _vm._v("八大银行开设香港账户全对比"),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "tips-1 theme-color" }, [
        _vm._v("备注：外籍人士或者身份证是集体户口的申请人，需要提供地址证明"),
      ]),
      _c("div", { staticClass: "finacing-footer" }, [
        _c(
          "div",
          { staticClass: "add-btn theme-bgcolor", on: { click: _vm.lx } },
          [_vm._v("预约开户")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-items" }, [
      _c("img", {
        staticClass: "img",
        attrs: { src: require("@/assets/img/tab1.jpg") },
      }),
      _c("img", {
        staticClass: "img",
        attrs: { src: require("@/assets/img/tab2.jpg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }